import React from 'react';

import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';

import logo from 'img/companyLogo/errorception.png';

const Content = () => {
  return (
    <div>
      <SubTitle>What is Errorception?</SubTitle>
      <p>
        Errorception is a tool to control the stability of your app and report errors. With this
        integration, you’ll access session replays directly from the Errorception issue details.
      </p>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <p>
        Every time a new session starts, a session link is sent to Errorception. This allows for
        direct access to session replays.
      </p>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        <strong>Important</strong>: Remember that LiveSession only starts recording if it detects
        any activity of user (
        <a
          href="https://developers.livesession.io/javascript-api/recording-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          read more{' '}
        </a>{' '}
        about recording conditions). For example, if an error occurs on rendering page, the session
        (in most cases) won’t start. This means that the Errorception issue won’t get a session
        recording link.
      </div>
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script>
__ls("getSessionURL", function (url, isNewSession) {
    _errs.meta = {
        sessionURL: url,
    };
});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        For this integration to work, you need to have both Errorception and LiveSession installed
        on your website. If you haven’t installed our tracking code yet, check{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>.
      </div>
    </div>
  );
};

const related = [];

export const frontmatter = {
  metaTitle: 'LiveSession and Errorception integration',
  canonical: '/help/errorception-integration/',
  metaDescription: 'Add LiveSession recordings to issue details in Errorception.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
